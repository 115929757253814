<template>
  <div>
    <Pane />

    <a-card class="container">
      <private-button path="add" type="primary" size="default">
        <span>新建</span>
      </private-button>

      <Padding />

      <a-table
        bordered
        :dataSource="dataDict"
        :loading="loading"
        :pagination="false"
      >
        <a-table-column
          key="name"
          title="名称"
          data-index="name"
        ></a-table-column>
        <a-table-column
          key="value"
          title="键值"
          data-index="value"
        ></a-table-column>
        <a-table-column key="icon" title="图标">
          <template slot-scope="text">
            <a-icon v-if="text.icon" :type="text.icon" />
            <div v-else>--</div>
          </template>
        </a-table-column>
        <a-table-column key="sort" title="排序">
          <template slot-scope="text">
            <div v-if="typeof text.sort === 'number'">
              {{ text.sort }}
            </div>
            <div v-else>--</div>
          </template>
        </a-table-column>
        <a-table-column
          key="createUserName"
          title="创建者"
          data-index="createUserName"
        ></a-table-column>
        <a-table-column
          key="createAt"
          title="创建时间"
          data-index="createAt"
        ></a-table-column>
        <a-table-column key="control" title="操作" align="center">
          <template slot-scope="text">
            <private-button
              path="edit"
              routeType="method"
              @clickButton="edit(text)"
            >
              <span>编辑</span>
            </private-button>

            <private-button
              path="delete"
              routeType="method"
              danger
              @clickButton="deleteText(text)"
              v-if="
                (text.children && text.children.length === 0) || !text.children
              "
            >
              <span>删除</span>
            </private-button>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { remove } from "@/api/setting/data-dict";
import { mapActions, mapState } from "vuex";

export default {
  data() {
    return {
      loading: false,
      rawList: [], // 原始的，未结构化的数据
    };
  },

  computed: {
    ...mapState("setting", ["dataDict"]),
  },

  mounted() {
    this.getList();
  },

  methods: {
    ...mapActions("setting", ["getDataDict"]),

    getList() {
      this.loading = true;

      this.getDataDict()
        .then(() => {})
        .finally(() => {
          console.log("this.datadict", this.dataDict);
          this.loading = false;
        });
    },

    filterList(list, pid) {
      const tree = [];
      list.forEach((item) => {
        if (item.pid === pid) {
          let children = this.filterList(list, item.id);

          tree.push({
            ...item,
            key: item.id,
            children: children && children.length > 0 ? children : undefined,
          });
        }
      });

      return tree;
    },

    edit(text) {
      const params = JSON.stringify(text);
      window.localStorage.setItem("edit-data-dict", params);
      this.$router.push(`${this.$route.path}/edit`);
    },

    deleteText(text) {
      const that = this;

      this.$confirm({
        title: "确认要删除这个字典吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
